.editor-container {
  background-color: #f3f6f9;
  display: flex;
  position: relative;
  height: 100vh;
}

.editor-sidebar {
  min-width: 350px;
  width: 350px;
  background-color: #333333;
  color: white;
  position: relative;
  font-size: 16px;
}

.iframe-container {
  width: 100%;
  padding: 0;
  margin: auto;
  box-shadow: rgba(43, 53, 86, 0.15) 0px 0px 60px 0px;
}

#template {
  width: 100%;
  height: 100vh;
  border: 0px;
  /* zoom: 0.9;
  transform-origin: 0 0;
  transform: scale(0.9); */
  margin: auto;
}

.editor-sidebar .logo {
  padding: 20px 20px;
  border-bottom: 1px solid #797575;
  margin-bottom: 20px;
}
.editor-sidebar .logo img {
  width: 70%;
}

.palette-container {
  display: flex;
  flex-direction: column;
}
.palette-section {
  padding: 8px 20px;
}
.palette-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

.palette-row input {
  outline: none;
  border: none;
}

.palette-row input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 25px;
  height: 25px;
  border: none;
  cursor: pointer;
}

.sidebar-footer {
  border-top: solid 1px #797575;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar-footer div {
  padding: 10px 20px;
  cursor: pointer;
}

.sidebar-footer div:nth-child(2) {
  border-right: 1px solid #797575;
  border-left: 1px solid #797575;
}

.sidebar-footer div img {
  width: 20px;
  height: 20px;
}

.editor-bottom-bar {
  display: none;
}

.pw-field{
  /* background-image: url('../images/bi_eye.svg'); */
  /* background-position: 98% 10px; */
  /* background-repeat: no-repeat; */
  padding-right: 40px;
}
#toggleCurrentPassword, #togglePassword, #toggleRePassword {
  position: absolute;
  right: 8px;
  top: 20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}
#eye-icon {
  width: 20px;
  height: auto;
}

#password::-ms-reveal,
#password::-ms-clear {
  display: none;
}
.pw-position{
  background-position: 96% 10px;
}
.ql-toolbar, .ql-container{
  border-color: #24306099 !important;
}

@media screen and (max-width: 782px) {
  .edit-delete-icon {
    position: absolute;
    right: 17px;
    top: 30px;
  }

}

.icon-tooltip-text{
  visibility: hidden;
  width: max-content;
  background-color: #243060;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  top: -47px;
  left: -60%;
  transform: translateX(-50%);
}
.icon-tooltip-text.brand-color-tooltip{
  left: -110%;
}

.site-menu .icon-tooltip-text{
  left: -10%;
}
.icon-tooltip-text::after {
  content: "";
  position: absolute;
  top: 97%;
  right: 10%;
  border-width: 7px;
  border-style: solid;
  border-color: #243060 transparent transparent transparent;
}
.site-menu .icon-tooltip-text:after{
  right: 12%;
}

.color-icon:hover .icon-tooltip-text,
.setting-icon:hover .icon-tooltip-text,
.site-menu:hover .icon-tooltip-text{
  visibility: visible;
}
.pageLabel {
  color: #3B3B3B66;
  width: 110px
}
.pageValue{
  margin-left: 0;
  width: calc(100% - 129px)
}

.image-loader-block {
   min-height: 700px;
}
.userprofile:hover {
  border: .0625rem solid #243060;
  border-radius: 5px;
}

.rti--container {
  --rti-bg: "#fff",
  --rti-border: "#ccc",
  --rti-main: "#3182ce",
  --rti-radius: "0.375rem",
  --rti-s: "0.5rem", /* spacing */
  --rti-tag: "#edf2f7",
  --rti-tag-remove: "#e53e3e",
}

.dns-service-providers .list-square{
  list-style: square;
}
.image-template{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.upper-block{
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  background-image: url("../images/white-bg-template.png");
  background-repeat: no-repeat;
}
.inner-block{
background-image: url("../images/spinner.svg");
background-repeat: no-repeat;
background-size: 20%;
background-position: center center;
height: 100%;
position: absolute;
width: 100%;
top: 25px;
height: calc(100% - 50px);
}

.image-upload-box{
  min-width: 340px;
  width: 340px;
}

.site-name-wrapper{
  width: calc(100% - 205px);
  justify-content: space-between;
}
.site-title-name{
  width: calc(100% - 315px);
}
.relative.isBlurred {
  background-color: #24306033;
}
#app .relative.isBlurred td:first-child {
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #f94343;
}
#app .relative.isBlurred td:last-child {
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: #f94343;
}
#app .relative.isBlurred td {
  border: 1px solid #d1d3de;
  border-top-style: solid;
}
table {
  border-spacing: 0;
}
.seeResponse {
  z-index: 1;
  border-radius: 16px;
}
tr.absolute + .isBlurred {
  border-top : 2px solid #F94343;
}
.isBlurred:last-child {
  border-bottom : 2px solid #f94343;
}
.isBlurred:last-child td {
  border-bottom : 3px solid #f94343;
}
.submissionTable tr {
  border-bottom: none;
}
.submissionTable .isBlurred:last-child td {
  border-bottom: 2px solid #f94343;
}

@media screen and (max-width: 1023px) {
  .editsite .MuiButton-startIcon, .previewsite .MuiButton-startIcon{
    margin-right:0px;
  }
  .site-title-name {
    width: calc(100% - 235px);
  }
}

@media screen and (max-width: 743px) {
.site-name-wrapper {
  justify-content: space-between;
  width: calc(100% - 32px);
}
}

@media screen and (max-width: 744px) {
  .editor-sidebar {
    display: none;
  }

  .iframe-container #template {
    height: calc(100vh - 40px);
    position: absolute;
    top: 0px;
  }

  .editor-bottom-bar {
    display: block;
    position: fixed;
    background-color: #333333;
    width: 100%;
    color: white;
    bottom: 0px;
    padding: 10px 0;
    z-index: 20;
    box-sizing: border-box;
  }

  .editor-bottom-bar .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
  }

  .editor-bottom-bar .header.open {
    border-bottom: solid 1px #797575;
    padding-bottom: 20px;
  }

  .expand-icon {
    width: 20px;
    cursor: pointer;
  }
  .bottom-logo {
    max-width: 150px;
  }
  .open-bottom-bar {
    height: 40vh;
    display: block;
    overflow: auto;
    padding-top: 10px;
  }
  .close-bottom-bar {
    display: none;
  }
  .palette-container {
    font-size: 0.8rem;
  }
  .template-filter{
    display: none;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #scaled-frame {
    zoom: 1;
  }
}

@media screen and (max-width: 600px) {
  .MuiButtonBase-root.editsite, .MuiButtonBase-root.previewsite {
    min-width: 36px;
  }
  .editsite .MuiButton-startIcon {
    margin-right:0px;
  }
  #app .page404 {
    height: 80%;
  }
  .icon-tooltip-text{
    left: -41%;
  }
  .icon-tooltip-text.brand-color-tooltip{
    left: -130%;
  }

  .icon-tooltip-text:after{
    right: 15%;
  }
  .icon-tooltip-text.brand-color-tooltip:after{
    right: 5%;
  }
}

@media screen and (max-width:526px) {
  .footer-div{
    flex-direction: column;
  }
  .pipe-1 {
    display: none;
  }
  .site-title-name {
    width: 40%;
  }
  .site-title-name.choose-temp {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .image-upload-box{
    min-width: 280px;
    width:280px;
  }
}

@media screen and (max-width: 1440px) {
 .submissionTable {
   width: 1200px;
   overflow-x: scroll;
 }
 .responseMonth {
   width: 1200px;
 }
}